
import { defineComponent, ref, onMounted, toRefs, SetupContext } from 'vue';
import { CheckboxProp } from '@/types/Input';

export default defineComponent({
  props: {
    modelValue: [String, Boolean],
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      defualt: '',
    },
  },
  emits: ['update:modelValue'],
  setup: (props: CheckboxProp, { emit }: SetupContext) => {
    const isChecked = ref<any>(false as boolean);
    const input = ref<any>(null);

    const { modelValue } = toRefs(props);

    onMounted(() => (isChecked.value = !!modelValue.value as boolean));

    const changed = () => {
      isChecked.value = input.value.checked;
      emit('update:modelValue', isChecked.value);
    };

    return {
      isChecked,
      changed,
      input,
    };
  },
});
